import React, { useState, useEffect } from "react";
import { Container, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import Section from './Section';
import Search from './Search';

const Category = () => {
    const [categoryList, setCategoryList] = useState([]);

    useEffect( () => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/categories`, {
                    method: "GET", 
                    mode: 'cors', 
                    headers: {
                        "Content-type": "application/json",
                        "x-functions-key": `${process.env.REACT_APP_API_CATEGORY_KEY}`
                    }
                });
                const data = await response.json();
                setCategoryList(data.data);
                
            } catch (error) {
                console.log(error);
            }
        };

        fetchCategories();
        window.history.replaceState({}, document.title);
    }, []);

    useEffect(() => {
        // Await for categoryList to be populated
        if ( categoryList.length > 0) {
            (function($) {
                $(".mobile-menu").slicknav({
                    prependTo: '#mobile-menu-wrap',
                    allowParentLinks: false
                });
            })(window.jQuery);
        }
    }, [categoryList]);

    return (
        <Router>
            <header>
                <Nav.Item>
                    <Container fluid>
                        <Nav className="nav-menu mobile-menu">
                            <li key="0"><NavLink to="/" className={({ isActive }) => (isActive ? "activeMenu" : null)}>Home</NavLink></li>
                            {categoryList.map((category) => (
                                <li key={category.id}><NavLink to={'/' + category.path} className={({ isActive }) => (isActive ? "activeMenu" : null)}>{category.name}</NavLink></li>        
                            ))}
                        </Nav>
                        <div id="mobile-menu-wrap"></div>
                    </Container>
                </Nav.Item>
            </header>

            <Routes>          
                <Route exact path="/" element={<Section />} />
                <Route path="/search" element={<Search />} />
                <Route path="/:categoryPath" element={<Section />} />
            </Routes>
        </Router>    
    );
}

export default Category;