import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ReactImageZoom from 'react-image-zoom';
import ReactLoading from 'react-loading';
import { useParams } from 'react-router-dom';

const Section = () => {
    const { categoryPath } = useParams();
//    const [filters, setFilters] = useState([]);
    const [products, setProducts] = useState([]);
//    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/section/${categoryPath || 'home'}`, {
                    method: "GET",
                    mode: 'cors',
                    headers: {
                        "Content-type": "application/json",
                        "x-functions-key": `${process.env.REACT_APP_API_SECTION_KEY}`
                    }
                });
                const data = await response.json();
//                setFilters(data.filters);
                setProducts(data.products);
                setFilteredProducts(data.products);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [categoryPath]);

    /*
    const handleFilterChange = (filterProperty, filterValue, checked) => {
        const newSelectedFilters = [...selectedFilters];
        const filter = newSelectedFilters.find(({ property }) => property === filterProperty);

        if (filter) {
            if (!checked) {
                const index = filter.values.indexOf(filterValue);
                filter.values.splice(index, 1);
            } else {
                filter.values.push(filterValue);
            }
        } else {
            newSelectedFilters.push({ property: filterProperty, values: [filterValue] });
        }

        const activeFilters = newSelectedFilters.filter(f => f.values.length > 0);
        const newFilteredProducts = activeFilters.length > 0
            ? products.filter(product => activeFilters.every(filter => filter.values.includes(product.properties[filter.property])))
            : products;

        setSelectedFilters(newSelectedFilters);
        setFilteredProducts(newFilteredProducts);
    };
    */

    const productList = filteredProducts.length ? filteredProducts : products;

    return (
        <section className="women-banner spad">
            <Container fluid>
                <Row className="row-cols-3=auto">
                    {/*
                    <Col className="filter-section">
                        <hr></hr>
                        <strong>Filtros</strong>
                        <hr></hr>
                        <Form>
                            {filters.map((filter, i) => (
                                <React.Fragment key={i}>
                                    <div className="filter-form">
                                        <strong>{filter.name}</strong>
                                    </div>

                                    <div className="mb-3 filter-form">
                                        {filter.values.map((value) => (
                                            <Form.Check key={filter.property + '_' + value} type="checkbox" id={filter.property} label={value} onChange={(event) => handleFilterChange(filter.property, value, event.target.checked)} />
                                        ))}
                                    </div>
                                    <hr />
                                </React.Fragment>
                            ))}
                        </Form>
                    </Col>
                    */}

                    <Col>
                        <div className="product-card">
                            {productList.length ? productList.map((product) => (
                                <div key={product.id} className="product-item">
                                    <div className="pi-pic">
                                        <ReactImageZoom
                                            img={`${process.env.REACT_APP_IMAGE_URL}/${product.id}.jpeg`}
                                            zoomPosition="original"
                                        />
                                    </div>
                                    <div className="pi-text">
                                        <div className="catagory-name">{product.category}</div>
                                        <h5>{product.name}</h5>
                                        <div className="product-price">
                                            R$ {product.price.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            )) :
                                <ReactLoading id="loading" type={'bubbles'} color={'#000000'} height={50} width={50} />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Section;